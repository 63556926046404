<template>
  <div id="app">
      <router-view></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status:0
    };
  },
  methods: {
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{
        if(!localStorage.getItem('token')){
          this.$router.push({path:'/Login'})
        }
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  created(){
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*, :after, :before {
  box-sizing: border-box;
}
::v-deep .van-dialog {
    position: fixed;
    top: 45%;
    left: 50%;
    width: 80.667vw !important;
    overflow: hidden;
    font-size: 4.133vw !important;
    background-color: #fff;
    border-radius: 2.133vw;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
}
::v-deep .van-dialog__header {
    padding-top: 3.467vw;
    font-weight: 500;
    line-height: 6.2vw !important;
    text-align: center;
}
::v-deep .van-dialog__message {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    max-height: 60vh;
    padding: 3.467vw 3.2vw !important;
    overflow-y: auto;
    font-size: 4.867vw !important;
    line-height: 4.667vw !important;
    white-space: pre-wrap;
    text-align: center;
    word-wrap: break-word;
    -webkit-overflow-scrolling: touch;
}
::v-deep .van-dialog__cancel, .van-dialog__confirm {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    height: 11.4vw !important;
    margin: 0;
    border: 0;
}
::v-deep .van-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    height: 5.867vw !important;
    margin: 0;
    padding: 0;
    font-size: 4.133vw  !important;
    line-height: 1.2;
    text-align: center;
    border-radius: 0.267vw;
    cursor: pointer;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    -webkit-appearance: none;
}
</style>
