<template>
  <div class="container page">
    <van-nav-bar :title="this.$t('填写收款账户')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
		<div class="label">{{$t('可选择添加类型')}}</div>
		<div class="label" style="display: flex; gap: 10px;">
			<div class="label2" @click="nowtype='card'" :class="{'on':nowtype=='card'}">{{$t('银行卡')}}</div>
			<div class="label2" @click="nowtype='zhifubao'" :class="{'on':nowtype=='zhifubao'}">{{$t('支付宝')}}</div>
			<div class="label2" @click="nowtype='weixin'" :class="{'on':nowtype=='weixin'}">{{$t('微信')}}</div>
		</div>
		<div style="padding: 10px;" v-if="nowtype=='card'">
			<van-cell-group>
				<van-field v-model="truename" :label="this.$t('真实姓名')" type="text" :placeholder="this.$t('请确保真实有效,否则影响兑换')" />
				<van-field v-model="bank" :label="this.$t('银行名称')" type="text" :placeholder="this.$t('银行名称')" />
				<van-field v-model="bank_son_name" :label="this.$t('开户支行')" type="text" :placeholder="this.$t('请输入开卡地址')" />
				<!-- <van-field v-model="bank_son_hao" :label="this.$t('支店番号')" type="text" :placeholder="this.$t('支店番号')" /> -->
				<van-field v-model="bankid" :label="this.$t('银行卡号')" type="text" :placeholder="this.$t('请手动输入此卡银行卡号码')" />
			</van-cell-group>
		</div>
		<div style="padding: 10px;" v-if="nowtype=='zhifubao'">
			<van-cell-group>
				<van-field v-model="truename" :label="this.$t('真实姓名')" type="text" :placeholder="this.$t('请确保真实有效,否则影响兑换')" />
				<van-field v-model="bankid" :label="this.$t('添加账号')" type="text" :placeholder="this.$t('请手动输入支付宝手机号')" />
				<div style="text-align: center;">上传支付宝二维码</div>
				<div class="upload_top">
					<div class="upload_div " style="display: flex; flex-direction: column; align-items:center; justify-content: center; padding: 10px;">
					<div class="upload_div_img" style="display: flex; justify-content: center; padding: 10px;">
						<img
							style="width: 200px; height: 200px;"
							v-if="img1"
							:src="data.img1"
						>
					</div>
					<van-uploader
						multiple
						:max-count="1"
						class="upload"
						:max-size="20 * 1024 * 1024"
						:after-read="afterRead1"
						:before-read="beforeRead"
						@oversize="onOversize"
					/>
					</div>
				</div>
			</van-cell-group>
		</div>
		<div style="padding: 10px;" v-if="nowtype=='weixin'">
			<van-cell-group>
				<van-field v-model="truename" :label="this.$t('真实姓名')" type="text" :placeholder="this.$t('请确保真实有效,否则影响兑换')" />
				<van-field v-model="bankid" :label="this.$t('微信手机号')" type="text" :placeholder="this.$t('请手动输入微信手机号')" />
				<div style="text-align: center;">上传微信二维码</div>
				<div class="upload_top">
					<div class="upload_div " style="display: flex; flex-direction: column; align-items:center; justify-content: center; padding: 10px;">
					<div class="upload_div_img" style="display: flex; justify-content: center; padding: 10px;">
						<img
							style="width: 200px; height: 200px;"
							v-if="img1"
							:src="data.img1"
						>
					</div>
					<van-uploader
						multiple
						:max-count="1"
						class="upload"
						:max-size="20 * 1024 * 1024"
						:after-read="afterRead1"
						:before-read="beforeRead"
						@oversize="onOversize"
					/>
					</div>
				</div>
			</van-cell-group>
		</div>
      <!-- <p>{{$t('親愛なるユーザー、あなたの資金の安全を確保するために、あなたの本当の名前と引き出しパスワードを設定してください。もし名前と口座名義が一致しない場合、引き出しができません。')}}</p> -->
    </div>
    <van-button class="bindCard" type="default" @click="bindCard()">{{$t('确认添加')}}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios'
import {
	Field,
	CellGroup,
	Uploader,
	Toast
} from "vant";
Vue.use(Field).use(CellGroup).use(Uploader).use(Toast);
export default {
	data() {
		return {
			uploadUrl: 'https://aqzwwz1.mlgm0pvq.shop/',
			img1: false,
			img2: false,
			data: {
				img1: "yonghu/infosf1.png",
				img2: "yonghu/infosf2.png",
			},
			imgType: 1,
			uploader:[],
			
			nowtype: 'card',
			banks: [],
			showBank:false,
			userInfo:{},
			bank:"",
			bank_son_name: "",
			bank_son_hao: "",
			bankid: "",
			truename:"",
		};
	},
  methods: {
		onOversize(file) {
			console.log("file", file)
			this.$toast(this.$t('请上传20M以内的图片'));
		},
		beforeRead(file) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
				this.$toast(this.$t('apply.图片仅支出jpg、jpeg、png格式'));
				return false;
			}
			return true;
		},
		afterRead1(file) {
			this.afterRead(file, 1);
		},
		afterRead2(file) {
			this.afterRead(file, 2);
		},
		afterRead(file, imgType) {
			let formData = new FormData();
			formData.append('file', file.file);
			formData.append('token', localStorage.getItem('token'));
			Toast.loading({
			forbidClick: true,
			duration: 20000,
			message: '上传中...',
			});
			axios.post(this.uploadUrl + "/api/member/uploadImg", formData).then((r) => {
				Toast.clear();
				console.log("r", r)
				if (r.data.code === 200) {
					this.$toast(this.$t('上传成功'));
					switch (imgType) {
					case 1:
					this.data.img1 = this.uploadUrl + r.data.data;
					this.img1 = true;
					console.log("this.data.img1", this.data.img1)
					break;
					case 2:
					this.data.img2 = this.uploadUrl + r.data.data;
					this.img2 = true;
					break;
					}
				} else {
					this.$toast(this.$t(r.data.msg));
				}
			});
		},
    back(){
		this.$router.back(-1)
		// return window.history.back();
    },
    bindCard(){
		if(this.userInfo.bankid){
			this.$toast(this.$t('请输入账号'));
			return true;
		}
		// if(this.bank === "" || this.bank === null || this.bank === undefined){
		//   this.$toast.fail("请选择银行！");
		//   return false;
		// }
		this.$http({
			method: 'post',
			data:{type:this.nowtype, img:this.data.img1, bank:this.bank, bank_son_name:this.bank_son_name, 
				bank_son_hao:this.bank_son_hao, bankid:this.bankid, truename:this.truename},
			url: 'user_set_bank'
		}).then(res=>{
			if(res.code === 200){
				this.$router.push({path:'/Mine'})
				this.$toast(res.msg);
			}else if(res.code ===401){
				this.$toast(res.msg);
			}
		})
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          this.name = res.data.name;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.label2{
	flex: 1;
	height: 90px;
	line-height: 90px;
	text-align: center;
	border: 1px solid #333;
}
.on{
	background-color: #7e5678;
	color: white;
}
.van-cell {
  font-size: 32px;
  line-height: 80px;
  height: 80px;
  margin-top: 10px;
  background: none;
}
.van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.bankbox{
  padding: 15px;
  color: #000;
  background-color: #fff;
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  background: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
