<template>
	<van-tabbar v-if="show" v-model="active" active-color="#7e5678" :border="true" inactive-color="#979799">
		<van-tabbar-item v-for="(k, key) in item" replace :to="k.router" :key="key">
			<span>{{ $t(k.title) }}</span>
			<template #icon="props">
				<img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title"/>
				<!-- <img :src="props.active ? k.icon.active : k.icon.noactive" :alt="k.title" class="tui" style="height: 4rem;" v-show="key === 2" /> -->
			</template>
		</van-tabbar-item>
	</van-tabbar>
</template>

<script>
export default {
	data() {
		return {
			show: false,
			active: 0,
			item: [
				{
					router: '/Choose',
					title: '首页',
					icon: {
						active: '/img/footer/homec.png',
						noactive: '/img/footer/home1.png'
					}
				},
				{
					router: '/Game2',
					title: '认证',
					icon: {
						active: '/img/footer/reserve.png',
						noactive: '/img/footer/reserve.png'
					}
				},
				// {
				// 	router: '/Choose',
				// 	title: '',
				// 	icon: {
				// 		active: '/img/footer/beauty.52660ad1.png',
				// 		noactive: '/img/footer/beauty.52660ad1.png'
				// 	}
				// },
				{
					router: '/VideoYindao',
					title: '福利',
					icon: {
						active: '/img/footer/videoc.png',
						noactive: '/img/footer/video.png'
					}
				},
				// {
				// 	router: '/Video',
				// 	title: '视频',
				// 	icon: {
				// 		active: '/img/footer/videoc.png',
				// 		noactive: '/img/footer/video.png'
				// 	}
				// },
				{
					router: '/Mine',
					title: '我的',
					icon: {
						active: '/img/footer/myc.png',
						noactive: '/img/footer/my1.png'
					}
				}
			]
		};
	},
	methods: {},
	watch: {
		// '$store.state.yuyan' (){
		// 	// alert(this.$store.getters.yuyan)
		// 	this.$i18n.locale = this.$store.getters.yuyan;
		// },
		$route(to) {
			if (to.name == 'choose') {
				this.active = 0;
				this.show = true;
			} else if (to.name == 'game') {
				this.active = 1;
				this.show = true;
			} 
			// else if (to.name == 'choose') {
			// 	this.active = 2;
			// 	this.show = true;
			// } 
			else if (to.name == 'yindao') {
				this.active = 2;
				this.show = true;
			} else if (to.name == 'mine') {
				this.active = 3;
				this.show = true;
			} else {
				this.show = false;
			}
		}
	},
	created() {
		if (this.$route.name == 'choose') {
			this.active = 0;
			this.show = true;
		} else if (this.$route.name == 'game') {
			this.active = 1;
			this.show = true;
		} 
		// else if (this.$route.name == 'choose') {
		// 	this.active = 2;
		// 	this.show = true;
		// } 
		else if (this.$route.name == 'yindao') {
			this.active = 2;
			this.show = true;
		} else if (this.$route.name == 'mine') {
			this.active = 3;
			this.show = true;
		} else {
			this.show = false;
		}
	}
};
</script>

<style lang="less" scoped>
@tabbar-height: 90px;
@tabbar-img: 45px;
.van-tabbar {
	height: @tabbar-height;
	background: rgb(40, 40, 40);
}
.van-tabbar-item--active{
	background: rgb(40, 40, 40);
	color: rgb(230, 185, 138) !important;
}
.van-tabbar-item__icon img {
	height: @tabbar-img;
}
.van-tabbar-item {
	font-size: 26px;
}
.tui {
	width: 4rem;
	margin-top: -10.333vw;
	background-color: white;
	border-radius: 50%;
	border: 10px solid white;
	z-index: 10;
}
[class*='van-hairline']::after {
	border: none !important;
}
</style>
