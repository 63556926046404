<template>
  <div class="container page">
    <van-nav-bar :title="this.videoInfo.vod_name" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="movie-video">
      <video id="my-video"  class="video-js">
      </video>
    </div>
    <div class="movie-content">
      <div class="movie-descript">
        <p>{{ this.videoInfo.vod_name}}</p>
        <span>{{this.videoInfo.count}}{{$t("次播放")}}</span>
      </div>

      <div class="movie-body">
        <div class="movie-title">
          <div>
            <span>{{$t('热门推荐')}}</span>
          </div>
        </div>
        <div class="movie-list">
          <div class="movie-play-item" @click="toPlayVideo(v.id)" v-for="(v,key) in moreVideoInfo" :key="key">
            <div>
              <img :src="v.vod_pic">
              <div>
                <div class="van-count-down">{{ v.time }}</div>
              </div>
            </div>
            <div>
              <p>{{ v.vod_name }}</p>
              <span>{{ v.count }}{{$t("次播放")}}</span>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Dialog } from 'vant';
// 全局注册
Vue.use(Dialog);
import videojs from "video.js";
import "videojs-contrib-hls";
export default {
  data() {
    return {
      nowPlayVideoUrl: "",
      cover:"",
      userInfo:[],
      videoInfo:{},
      moreVideoInfo:{},
      player:null
    };
  },
  methods: {
    back(){
		this.$router.push({path:'Video'})
        // this.$router.push({path:'Home'})
    },
    getVideoInfo(){
      this.$http({
        method: 'get',
        data:{id:this.$route.query.id},
        url: 'video_get_info'
      }).then(res=>{
        this.videoInfo = res.data;
        this.nowPlayVideoUrl = this.videoInfo.vod_play_url;
        this.cover = this.videoInfo.vod_pic;
        let videos = document.getElementById('my-video');
        videos.poster = this.cover;
        this.getVideo();
      })

    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'?id='+id})
        location.reload();
      }

    },
    getMoreVideoItem(){
      this.$http({
        method: 'get',
        url: 'video_get_more_item'
      }).then(res=>{
          this.moreVideoInfo = res.data;
      })
    },
    getVideo() {
      this.player = videojs("my-video",  {
        height:"200px",
        preload: "auto", // 预加载
        controls: true,  // 显示播放的控件
        multipleArray: [0.75, 1, 1.5, 2], // 倍速设置
      });
      this.player.src([{
        src: this.nowPlayVideoUrl,
        type: "application/x-mpegURL" // 告诉videojs,这是一个hls流
      }]);

    },
	checkTime(){
		let that = this;
		if(this.player == null || this.player == undefined){
			return true;
		}
		console.log("cache_", this.player.cache_.currentTime)
		let see_num = this.$store.getters.getBaseInfo.see_num;
		if(see_num == null || see_num == undefined){
			see_num = 1;
		}
		
		
		if(see_num * 60 < this.player.cache_.currentTime){
		// if(2 < this.player.cache_.currentTime){
			//超过了免费时长，就检查是否余额了
			
			if(this.$store.getters.getBaseInfo.isplay == 1){
				if(this.userInfo.money < this.$store.getters.getBaseInfo.see_shipin_min){
					
					Dialog.alert({
						title: that.$t('提示'),
						message: that.$t('请联系客服充值后，再观看视频！'),
					}).then(() => {
						// on close
						// this.$router.push({path:'/Home'})
					});
					
					// this.$toast(this.$t('请充值后观看视频！'));
					// this.$router.push({path:'/Home'})
				}
			}
		}
		
		
		// console.log("this.player", this.player);
		
	},
    getUserInfo(){
		this.$http({
			method: 'get',
			url: 'user_info'
		}).then(res=>{
			if(res.code === 200){
				this.userInfo = res.data;
				if(this.userInfo.status !== 1){
					this.$toast(this.$t('账号下线'));
					localStorage.clear()
					this.$router.push({path:'/Login'})
				}else {
					console.log("this.$store.getters.getBaseInfo", this.$store.getters.getBaseInfo)
					
					console.log("this.userInfo.money", this.userInfo)
					this.getVideoInfo();
					this.getMoreVideoItem();
					
					// if(this.$store.getters.getBaseInfo.isplay == 1){
					// 	this.getVideoInfo();
					// 	this.getMoreVideoItem()
					// 	if(this.userInfo.money <= "0.00"){
					// 		this.$toast(this.$t('请充值后观看视频！'));
					// 		this.$router.push({path:'/Home'})
					// 	}
					// }else {
					// 	this.getVideoInfo();
					// 	this.getMoreVideoItem();
					// }
					
					
				}
			}else if(res.code ===401){
				this.$toast(res.msg);
			}
		})
    },
  },
	created() {
		let that = this;
		if(!localStorage.getItem('token')){
			this.$router.push({path:'/Login'})
		}else {
			this.getUserInfo();

			setInterval(function(){
				that.checkTime()
			}, 3000)
		}
	},
	destroyed () {
		this.player.dispose()
	}
};
</script>

<style lang='less' scoped>
::v-deep .van-dialog {
    position: fixed;
    top: 45%;
    left: 50%;
    width: 80.667vw !important;
    overflow: hidden;
    font-size: 4.133vw !important;
    background-color: #fff;
    border-radius: 2.133vw;
    -webkit-transform: translate3d(-50%, -50%, 0);
    transform: translate3d(-50%, -50%, 0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: .3s;
    transition: .3s;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
}
::v-deep .van-dialog__header {
    padding-top: 3.467vw;
    font-weight: 500;
    line-height: 6.2vw !important;
    text-align: center;
}
::v-deep .van-dialog__message {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    max-height: 60vh;
    padding: 3.467vw 3.2vw !important;
    overflow-y: auto;
    font-size: 4.867vw !important;
    line-height: 4.667vw !important;
    white-space: pre-wrap;
    text-align: center;
    word-wrap: break-word;
    -webkit-overflow-scrolling: touch;
}
::v-deep .van-dialog__cancel, .van-dialog__confirm {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    flex: 1;
    height: 11.4vw !important;
    margin: 0;
    border: 0;
}
::v-deep .van-button {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
	height: 50px !important;
    /* height: 5.867vw !important; */
    margin: 0;
    padding: 0;
    font-size: 4.133vw  !important;
    line-height: 1.2;
    text-align: center;
    border-radius: 0.267vw;
    cursor: pointer;
    -webkit-transition: opacity .2s;
    transition: opacity .2s;
    -webkit-appearance: none;
}

.video-js {
  width: 100%;
  /*height: 420px;*/
  font-size: 24px;
}
.movie-content{
  flex: 1;
  overflow-y: auto;
}
.movie-content .movie-descript{
  width: 100%;
  height: 140px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  padding: 15px;
}
.movie-content .movie-descript p{
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-descript span{
  color: #979799;
}
.movie-content .movie-body{
  width: calc(100% - 20px);
  margin: 0 auto;
}
::v-deep .movie-video .video-js .vjs-big-play-button {
  top: 50%;
  left: 50%;
  margin-top: -50px;
  margin-left: -100px;
}
.movie-content .movie-body .movie-title{
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.movie-content .movie-body .movie-title>div:first-child {
  width: 200px;
}
.movie-content .movie-body .movie-title>div:first-child span{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 30px;
  font-weight: 700;
  color: #000;
}
.movie-content .movie-body .movie-title>div:first-child span:before {
  content: "";
  display: block;
  width: 8px;
  height: 30px;
  background-color: #7e5678;
  border-radius: 25px;
  margin-right: 10px;
}
.movie-play-item{
  width: 100%;
  height: 200px;
  border-radius: 10px;
  position: relative;
  display: flex;
  background-color: #fff;
  margin-bottom: 20px;
}
.movie-play-item>div{
  height: 100%;
}
.movie-play-item>div:first-child {
  width: 200px;
  position: relative;
}
.movie-play-item>div:first-child>img{
  width: 100%;
  height: 100%;
  border-radius: 10px 0 0 10px;
}
.movie-play-item>div:first-child>div{
  position: absolute;
  width: 100%;
  height: 30px;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  background-color: rgba(0,0,0,.4);
  border-radius: 0 0 0 10px;
}
.movie-play-item>div:first-child>div .van-count-down {
  color: #fff;
  font-size: 25px;
}
.movie-play-item>div:nth-child(2) p{
  width: 500px;
  height: 60px;
  font-size: 30px;
  line-height: 32px;
  word-break: break-all;
  overflow: hidden;
  color: #000;
}
.movie-play-item>div:nth-child(2) span{
  color: #000;
}
.movie-play-item>div:nth-child(2) {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
abbr, address, article, aside, audio, b, blockquote, body, canvas, caption, cite, code, dd, del, details, dfn, div, dl, dt, em, fieldset, figcaption, figure, footer, form, h1, h2, h3, h4, h5, h6, header, hgroup, html, i, iframe, img, ins, kbd, label, legend, li, mark, menu, nav, object, ol, p, pre, q, samp, section, small, span, strong, sub, summary, sup, table, tbody, td, tfoot, th, thead, time, tr, ul, var, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  box-sizing: border-box;
}
</style>