<template>
  <div class="container page">
    <van-nav-bar :title="this.$t('实名认证')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main-box">
      <div class="label" style="margin-top: 4vw; color: #ddb285; text-align: center;">{{$t('请输入您的身份信息')}}</div>
      <van-cell-group>
        <van-field v-model="truename" :label="this.$t('姓名')" type="text" :placeholder="this.$t('請輸入真實姓名')" />
        <van-field v-model="idcard" :label="this.$t('身份证号')" type="text" :placeholder="this.$t('请输入身份证号')" />
      </van-cell-group>
		<div class="upload_top">
			<div class="upload_div ">
			<div class="upload_div_img">
				<img
					v-if="img1"
					:src="require('../img/'+data.img1)"
				>
				<img
					v-if="img1"
					:src="data.img1"
				>
			</div>
			<van-uploader
				multiple
				:max-count="1"
				class="upload"
				:max-size="20 * 1024 * 1024"
				:after-read="afterRead1"
				:before-read="beforeRead"
				@oversize="onOversize"
			/>
			<div class="upload_tips">
				<p>{{$t('身份证正面照上传')}}</p>
			</div>
			</div>
		</div>
		<div class="upload_top">
			<div class="upload_div ">
				<div class="upload_div_img">
					<img
						v-if="img2"
						:src="require('../img/'+data.img2)"
					>
					<img
						v-if="img2"
						:src="data.img2"
					>
				</div>
				<van-uploader
					multiple
					:max-count="1"
					class="upload"
					:max-size="20 * 1024 * 1024"
					:after-read="afterRead2"
					:before-read="beforeRead"
					@oversize="onOversize"
				/>
				<div class="upload_tips">
					<p>{{$t('身份证反面照上传')}}</p>
				</div>
			</div>
		</div>
    </div>
	
	
	<van-button class="bindCard" type="default" v-if="userInfo.shiming_status==1">{{$t('审核中')}}</van-button>
    <van-button class="bindCard" type="default" v-if="userInfo.shiming_status==0 || userInfo.shiming_status==3" @click="bindCard()">{{$t('确认提交')}}</van-button>
    <van-popup v-model="showBank" round position="bottom" :style="{ height: '35%' }" >
      <van-picker
          show-toolbar
          :columns="banks"
          @confirm="onConfirm"
          @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import Vue from 'vue';
// import bsHeader from '../../components/bsHeader.vue'
// import Fetch from '../../utils/fetch'
// import Api from "../../interface/index";
import axios from 'axios'
import {
	Field,
	CellGroup,
	Uploader,
	Toast
} from "vant";

Vue.use(Field).use(CellGroup).use(Uploader).use(Toast);
export default {
  data() {
    return {
		uploadUrl: 'https://aqzwwz1.mlgm0pvq.shop/',
		img1: false,
		img2: false,
		img3: false,
		img4: false,
		data: {
			name: "",
			idcard: "",
			img1: "yonghu/infosf1.png",
			img2: "yonghu/infosf2.png",
			img3: "yonghu/infosf3.png",
			img4: "yonghu/infosf4.png"
		},
		imgType: 1,
		
		banks: [],
		showBank:false,
		userInfo:{},
		uploader:[],
		uploader2:[],
		truename:"",
		idcard:""
    };
  },
	methods: {
		onOversize(file) {
			console.log("file", file)
			this.$toast(this.$t('apply.请上传20M以内的图片'));
		},
		beforeRead(file) {
			if (file.type !== 'image/jpeg' && file.type !== 'image/jpg' && file.type !== 'image/png') {
				this.$toast(this.$t('apply.图片仅支出jpg、jpeg、png格式'));
				return false;
			}
			return true;
		},
		afterRead1(file) {
			this.afterRead(file, 1);
		},
		afterRead2(file) {
			this.afterRead(file, 2);
		},
		afterRead3(file) {
			this.afterRead(file, 3);
		},
		afterRead4(file) {
			this.afterRead(file, 4);
		},
		afterRead(file, imgType) {
			let formData = new FormData();
			formData.append('file', file.file);
			formData.append('token', localStorage.getItem('token'));
			Toast.loading({
				forbidClick: true,
				duration: 20000,
				message: '上传中...',
			});
			axios.post(this.uploadUrl + "/api/member/uploadImg", formData).then((r) => {
				Toast.clear();
				console.log("r", r)
				if (r.data.code === 200) {
					this.$toast(this.$t('apply.上传成功'));
					switch (imgType) {
						case 1:
							this.data.img1 = this.uploadUrl + r.data.data;
							this.img1 = true;
							break;
						case 2:
							this.data.img2 = this.uploadUrl + r.data.data;
							this.img2 = true;
							break;
						case 3:
							this.data.img3 = this.uploadUrl + r.data.data;
							this.img3 = true;
							break;
						case 4:
							this.data.img4 = this.uploadUrl + r.data.data;
							this.img4 = true;
							break;
					}
				} else {
					this.$toast(this.$t(r.data.msg));
				}
			});
		},
    back(){
		this.$router.back(-1)
    },
    bindCard(){
		if(this.truename == ""){
			this.$toast(this.$t('请输入姓名'));
			return true;
		}
		if(this.idcard == ""){
			this.$toast(this.$t('请输入身份证号'));
			return true;
		}
		// if(this.bank === "" || this.bank === null || this.bank === undefined){
		//   this.$toast.fail("请选择银行！");
		//   return false;
		// }
		
		
		this.$http({
			method: 'post',
			data:{name:this.truename, idcard:this.idcard, cover_zm:this.data.img1, cover_fm:this.data.img2},
			url: 'user_set_renzheng'
		}).then(res=>{
			if(res.code === 200){
				this.$router.push({path:'/Mine'})
				this.$toast(res.msg);
			}else if(res.code ===401){
				this.$toast(res.msg);
			}
		})
		
		
    },
    showSelectBanks(){
      this.showBank = true;
    },
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
			console.log("datas", res.data)
			this.userInfo = res.data;
			this.truename = res.data.name;
			this.idcard   = res.data.idcard
			this.data.img1= res.data.cover_zm
			if(this.data.img1 != null && this.data.img1 != ""){
				this.img1 = true;
			}
			this.data.img2= res.data.cover_fm
			if(this.data.img2 != null && this.data.img2 != ""){
				this.img2 = true;
			}
			
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    getBankList(){
      this.$http({
        method: 'get',
        url: 'sys_get_banks'
      }).then(res=>{
        if(res.code === 200){
          this.banks = res.data;
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    },
    onConfirm(value) {
      this.bank = value.text
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserRenzheng(){
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res=>{
        if(res.code === 200){
          if(res.data.is_bank){
            this.is_bind = true;
          }else {
            this.is_bind = false;
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
      this.getBankList();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.upload_top .upload_div .upload_tips {
    color: #fff;
    font-size: 14px;
    background: #9cf;
    width: 78%;
    margin-left: 11%;
    opacity: 0.5;
    padding: 7px 9px;
    border-radius: 5px;
    margin-top: 10px;
}
.upload_div {
    height: 40vw;
    width: 80%;
    margin: 0 10%;
    background-size: 100% 100%;
    text-align: center;
}
.upload_div .upload_div_img {
    width: 80%;
    height: 40vw;
    position: absolute;
    left: 10%;
	background-color: #fff;
}
.upload_div .upload_div_img img {
	height: 40vw;
}
.upload_top {
    margin-bottom: 3vw;
    height: 45vw;
    padding: 2vw 0;
}
.van-uploader__upload {
    background: url(../img/infocamera.png) no-repeat center center;
    background-size: 100% 100%;
    width: 15vw;
    height: 15vw;
	background-color: #282828;
}
::v-deep .van-uploader__upload {
    background: url(../img/infocamera.png) no-repeat center center;
    background-size: 100% 100%;
    width: 15vw;
    height: 15vw;
	background-color: #282828 !important;
}
.upload_top .upload_div .upload {
    margin-top: 10px;
}
.van-cell {
  font-size: 32px;
  line-height: 80px;
  height: 80px;
  background: #282828 !important;
  color: white;
}
.van-hairline--bottom::after {
  /* border-bottom-width: 3px; */
}
::v-deep .van-field__control{
	color: white !important;
}
.bankbox{
  padding: 15px;
  color: #fff;
  /* background-color: #fff; */
}
.bankbox .title{
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}
.main-box{
  color: #fff;

}
.main-box .label{
  padding: 20px;
  font-size: 35px;
  color: #797878;
}
::v-deep .van-picker__toolbar {
  height: 50px;
}
::v-deep .van-picker__cancel, .van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}
::v-deep .van-picker-column {
  font-size: 40px;
}
.main-box p{
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}
.bindCard {
  margin: 6vw 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      90deg,#e6c3a1,#7e5678);
}
</style>
