<template>
  <div class="container page">
      <van-nav-bar :title="$t('设置')" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()"/>
        </template>
      </van-nav-bar>
      <div class="items">
        <div class="item van-hairline--bottom" @click="toInfomation()">
          <div class="left">{{$t('基本信息设置')}}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toLoginPassword()">
          <div class="left">{{$t('登录密码')}}</div>
          <van-icon name="arrow" />
        </div>
        <div class="item van-hairline--bottom" @click="toPayPassword()">
          <div class="left">{{$t('资金密码')}}</div>
          <div class="right">
            <span class="desc">{{$t(this.userInfo.paypassword)}}</span>
            <van-icon name="arrow" />
          </div>
        </div>
		<div class="item van-hairline--bottom" @click="showYuyan()">
			<div class="left">{{$t('选择语言')}}</div>
			<van-icon name="arrow" />
		</div>
      </div>
      <van-button class="sign-out" type="primary" size="normal" @click="loginout()">{{$t('退出登录')}}</van-button>
	<van-popup v-model="showLotteryList" position="center" :style="{ height: '60%' }" >
		<div class="confirm-order-modal">
			<div class="head van-hairline--bottom">
				<p class="text">{{$t('语言列表')}}</p>
			</div>
			<ui class="list">
				<li v-for="(v,key) in lotteryGameList" :key="key" @click="switchLotteryGame(v)" class="lise-item van-hairline--bottom">
					<div class="maintt" style="align-items: center; border-bottom: 1px solid rgb(199 199 199); width: 100%;display: flex; justify-content: space-between;">
						<div class="bet-namesss" style="color: black;  line-height: 2.5rem;">{{ v.name }}</div>
						<van-icon class="font-gray" color="black" style="font-size: 16px" name="arrow" />
					</div>
				</li>
			</ui>
		</div>
	</van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
		userInfo:{},
		showLotteryList: false,
		lotteryGameList:[{
			id:1,
			code: 'en',
			name: 'English',
		},
		{
			id:2,
			code: 'taiyu',
			name: 'ภาษาไทย',
		},
		{
			id:3,
			code: 'riyu',
			name: '日本語',
		},
		{
			id:4,
			code: 'hanyu',
			name: '한국어',
		},
		{
			id:5,
			code: 'yulan',
			name: 'Tiếng Việt',
		}],
    };
  },
  methods: {
    back(){
		this.$router.back(-1)
    },
    toPayPassword(){
      if(this.userInfo.paypassword !== "未設定"){
        this.$toast(this.$t('提现密码已设置，需要修改请联系客服'));
      }else {
        this.$router.push({path:'/SetPayPassword'});
      }
    },
    toLoginPassword(){
      this.$router.push({path:'/SetLoginPassword'});
    },
    toInfomation(){
      this.$router.push({path:'/Infomation'});
    },
    loginout(){
        localStorage.clear()
        this.$router.push({path:'/Mine'});
    },
    toServicePage(){
      this.$router.push("ServicePage");
    },
	switchLotteryGame(e){
		console.log("item", e)
		this.$i18n.locale = e.code;
		this.showLotteryList = false;
		this.$store.commit('setYuyan', e.code);
		this.$router.push({path:'/Mine'});
	},
	showYuyan(){
		this.showLotteryList = true;
	},
    getUserInfo(){
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res=>{
        if(res.code === 200){
          this.userInfo = res.data;
          if(res.data.paypassword){
            this.userInfo.paypassword = "已設定";
          }else {
            this.userInfo.paypassword = "未設定";
          }
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }
  },
  created() {
    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserInfo();
    }
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.confirm-order-modal{
	height: 100%;
	max-height: 100%;
}
.container .items{
  background-color: #fff;
  font-size: 30px;
  color: #000;
  padding: 0 25px;
}
.container .items .item{
  padding: 30px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom-width: 10px;
}
.container .items .van-hairline--bottom::after {
  border-bottom-width: 3px;
}
.container .sign-out{
  margin: 500px 20px 0;
  height: 100px;
  line-height: 100px;
  border-radius: 50px;
  color: #fff;
  font-size: 40px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(
      270deg,#e6c3a1,#7e5678);
}
.container  .item .desc{
  font-size: 30px;
  font-weight: 700;
  color: #979799;
}
.container .item .right{
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>
