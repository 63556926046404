<template>
	<div class="convention-hall page">
		<van-nav-bar :title="this.$t('温州嘉纳传媒有限公司')" class="nav-bar">
			<!-- <template #left>
				<span class="putong"  @click="showAddressAll">{{$t('全部')}}</span>
			</template> -->
			<!-- <template #right >
				<span class="dingwei_icon"></span>
				<span class="putong" @click="showAddressFun">{{$t('切换')}}</span>
			</template> -->
		</van-nav-bar>
		
		<van-popup v-model="showAddress" class="cityPopup" position="center" round :style="{ height: '50%', width: '300px' }">
		<div class="address_box">
			<div style="color: #f54c48; padding: 15px 0;">{{$t('热门地区')}}</div>
			<div class="title list van-hairline--bottom">
				<div class="text" v-for="(val, key) in addlist" :key="key">
					<div class="items" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</div>
				</div>
			</div>
		</div>
		</van-popup>
		
		<div style="height:11.333vw; line-height:13.333vw; width:100%;"></div>
		
		
		
		
		<div class="banner">
			<!-- <van-swipe class="banner_swiper my-swipe" :autoplay="30000" indicator-color="white">
				<van-swipe-item class="ww100" v-for="(v, key) in banners" :key="key">
					<van-image class="ww100" fit="contain" :src="v.url" ></van-image>
				</van-swipe-item>
			</van-swipe> -->
			
			<swiper class="banner_swiper">
				<swiper-slide v-for="(v,key) in banners" :key="key">
					<van-image class="banner_img" fit="cover" :src="v.url" style="height: 150px;">
						<template v-slot:loading>
							<van-loading type="circular"/>
						</template>
					</van-image>
				</swiper-slide>
			</swiper>
			
		</div>
		
		<div class="convention-item">
			<div class="card">
				<div class="ad flex-row">
					<div class="flex-column center" style="border-right: 1px solid #f6d5a1;width: 38%;margin-bottom: 2.5vw;">
						<div class="btitle">{{$t('温州嘉纳传媒有限公司')}}</div>
						<!-- <div class="fulipinpai">{{$t('娱乐第一福利品牌')}}</div> -->
					</div>
					<div class="white" style="width: 62%;
						color: #dadada;
						font-size: 12px;
						padding-left: 10px;">
						{{$t('为了会员权益，网站只展示')}}<b style="color: red; font-size: 1rem;">1%</b>
						{{$t('资源并且不定期更新只为展示实力，如需查询预约更多资源请联系上级接待')}}
					</div>
				</div>
				<div class="summery">{{$t('本平台所有资源真实丶安全丶可靠，全国空降丶同城约炮选取你心仪的她，与您零距离邂逅，快加入我们吧')}}</div>
			
			</div>
			
			<van-tabs animated sticky line-width="100px" :swipeable="true">
				<van-tab :title="$t('温州嘉纳传媒有限公司资源')">
					<!-- <div class="address">
						<van-row type="flex" justify="" gutter="65" v-for="(val, key) in addlist" :key="key">
							<van-col span="" offset="" @click="addgo(v)" v-for="(v, k) in val" :key="k">{{ v.name }}</van-col>
						</van-row>
					</div> -->
					
					<van-pull-refresh v-model="isLoading" @refresh="onRefresh">
						<div class="ulist">
							<van-list
								v-model="loading"
								:finished="finished"
								:immediate-check="false"
								:finished-text="$t('没有更多了')"
								@load="onLoad"
							>
							<div class="item flex-column" type="flex-column" justify="" gutter="65" v-for="(val, key) in list" :key="key">
								<div class="flex-row one" @click="toDetail(val)">
									<span class="hasicon"><font class="biaoqian">{{$t('已认证')}}</font></span>
									
									<div class="uinfo flex-column">
										<div class="bianhao">{{$t('编号')}}：{{val.id}}</div>
										<div class="white hang mt10">{{$t('昵称')}}：{{val.xuanfei_name}}</div>
										<div class="white hang">{{$t('类型')}}：{{val.leixing}}</div>
										<div class="white hang">{{$t('所在地区')}}：{{val.name}}</div>
										<div class="white hang">{{$t('服务项目')}}：{{val.summery}}</div>
										<div class="white hang mt10">
											<div class="flex-row gap20">
												<div class="huihui  flex-row"><span class="icon_read"></span>{{val.read_num}}K</div>
												<div class="huihui  flex-row"><span class="icon_digg"></span>{{val.digg_num}}K</div>
											</div>
										</div>
									</div>
									<div class="uinfo_cover">
										<van-image
											width="100"
											height="100"
											fit="cover"
											:src="val.cover"
										/>
										<!-- <div class="cover" :style="'background-image: url('+require(val.cover)+'); background-position: center center; background-size: cover; background-repeat: no-repeat;'"></div> -->
										<!-- <img class="cover" :src="val.cover" /> -->
										
									</div>
								</div>
							</div>
							</van-list>
						</div>
					</van-pull-refresh>
					
				</van-tab>
				<van-tab :title="$t('服务条款')">
					<div class="rig-box ">
						<p class="rig-title">平台资源</p>
						<p class="rig-content">网红丶模特丶空姐丶嫩模丶大学生，只有您想不到，没有本平台办不到</p>
						<p class="rig-title">服务范围</p>
						<p class="rig-content">同城约炮，任意地点空降，国内一二线城市当地均有，三线城市也可以通过上级预约安排。</p>
						
						<!-- <p class="rig-title">{{$t('有哪些资源')}}?</p>
						<p class="rig-content">{{$t('网红、模特、空姐、嫩模、大学生，只有您想不到，没有本平台办不到')}}</p>
						<p class="rig-title">{{$t('服务范围')}}?</p>
						<p class="rig-content">{{$t('同城免费约炮，任意地点全国空降，国内一二线城市当地均有，三线城市也可通过联系接待员预约安排')}}。</p>
						<p class="rig-content">{{$t('本平台全网最真')}}</p> -->
					</div>
				</van-tab>
			</van-tabs>
		</div>
		
		
		
		
		
		
	</div>
	
	
	
</template>

<script>
import { Toast } from 'vant';
export default {
	data() {
		return {
			showAddress: false,
			banners: [{}],
			
			bannerSwiperOption: {
				effect: 'coverflow',
				grabCursor: true,
				centeredSlides: true,
				slidesPerView: 'auto',
				speed:800,
				autoplay:true,
				coverflowEffect: {
					rotate: 0,
					stretch: 10,
					depth: 100,
					modifier: 1,
					slideShadows : true
				}
			},
			list:[],
			page: 1,
			count:0,
			isLoading: false,
			loading: false,
			finished: false,
			refreshing: false,
			videolitem: [],
			
			
			address_id:0,
			addlist: [
				{
					0: '北京',
					1: '上海',
					2: '广州',
					3: '深圳'
				},
				{
					0: '南京',
					1: '长沙',
					2: '重庆',
					3: '西安'
				}
			]
		};
	},
	methods: {
		toDetail(item){
			this.$router.push({path:'/Profile?id='+item.id})
		},
		showAddressFun() {
			this.showAddress = true;
		},
		showAddressAll(){
			this.list = [];
			this.count = 0;
			this.page = 1;
			this.showAddress = false;
			this.address_id = 0;
			this.getList();
		},
		addgo(data) {
			this.list = [];
			this.count = 0;
			this.page = 1;
			this.showAddress = false;
			this.address_id = data.id;
			this.getList();
			
			// if (!localStorage.getItem('token')) {
			// 	this.$router.push({ path: '/Login' });
			// } else {
			// 	this.$router.push({ path: '/list?id=' + data.id + '&name=' + data.name });
			// }
		},
		getBanner(data){
			this.banners = data.banners;
		},
		getAddress() {
			this.$http({
				method: 'get',
				url: 'address_list'
			}).then(res => {
				this.addlist = res.data;
				console.log("this.addlist", this.addlist)
				
			});
		},
		getBasicConfig(){
			this.$http({
				method: 'get',
				url: 'sys_config'
			}).then(res=>{
				console.log("config", res.data)
				this.basicData = res.data;
				this.getBanner(this.basicData);//获取banner
			})
		
		},
		getList(){
			this.$http({
				method: 'post',
				data:{id:this.address_id, page:this.page},
				url: 'xuanfeilist'
			}).then(res=>{
				console.log("xuanfeilist", res)
				this.list = this.list.concat(res.data.list);
				this.count = res.data.count;
				this.page++;
				if (this.list.length === this.count) {
					console.log(this.list.length+"  "+this.count);
					this.finished = true;
				}
		
			})
		},
		onLoad() {
			console.log("onload")
			this.getList();
		},
		
		OnChange(){
			this.list = [];
			this.page = 1;
			this.count = 0;
			this.getList();//获取视频列表
		},
		onRefresh() {
			console.log("onrefresh")
			setTimeout(() => {
				this.finished = false;
				this.loading = true;
				this.onLoad();
				this.isLoading = false;
				Toast(this.$t('刷新成功'));
			}, 500);
		},
		
	},
	
	created() {
		this.getBasicConfig();
		this.getAddress();
		this.OnChange()
	}
};
</script>

<style lang="less" scoped>
.my-swipe .van-swipe-item {
	color: #fff;
	font-size: 20px;
	line-height: 150px;
	text-align: center;
	background-color: #39a9ed;
}
.custom-indicator {
	position: absolute;
	right: 5px;
	bottom: 5px;
	padding: 2px 5px;
	font-size: 12px;
	background: rgba(0, 0, 0, 0.1);
}
.my-swipe{
	width: 100%;
	height: 100%;
}
.mt10{
	margin-top: 2vw;
}
.icon_read{
	width: 4vw;
	height: 4vw;
	background: url(/img/read.png) no-repeat;
	background-size: cover;
	display: inline-block;
}
.icon_digg{
	width: 4vw;
	height: 4vw;
	background: url(/img/digg.png) no-repeat;
	background-size: cover;
	display: inline-block;
}
.card{
	border: 1px solid #766350;
	background: linear-gradient(120deg, #212121, #313131);
	padding-bottom: 2.5vw;
}
.btitle{
	font-weight: 1000;
	background-image: -webkit-linear-gradient(top, #f8dbae, #edbd71);
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	font-size: 1.1rem;
	text-align: center;
	margin: 0px 10px 0 10px;
}
.fulipinpai{
	color: #ddb285;
	font-size: 0.8rem;
	text-align: center;
}
.summery{
	color: #ddb285;
	font-size: 0.8rem;
	text-align: center;
}
.dingwei_icon{
	background: url(/img/dingwei.png);
	background-size: cover;
	width: 4vw;
	height: 4vw;
	margin: 0px 2px;
}
.address_box .list{
	height: 50vw;
	overflow: auto;
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	color: #fff;
}
.cityPopup .items {
	color: white;
	padding: 5px 10px 5px 20px;
	font-size: 1rem;
}
	
.cityPopup {
    border: 1px solid #7e6a56;
    border-radius: 10px;
    text-align: center;
    padding: 10px 10px 30px;
    background: linear-gradient(120deg, #212121, #313131);
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
	background: linear-gradient(120deg, #212121, #313131);
	position: relative;
	overflow: hidden;
	margin-bottom: 1.333vw;
	border-radius: 1.333vw;
	border: 1px solid #7e6a56;
	padding: 0vw 3.333vw;
	justify-content: space-between;
	color: #fff;
}
	
	
	
::v-deep .van-tabs--line .van-tabs__wrap
{
	height: 7vw;
}
::v-deep .van-tabs__nav--line {
	box-sizing: content-box;
	height: 100%;
	padding-bottom: 0vw;
	justify-content: left;
	display: flex;
	flex-direction: row;
}
::v-deep .van-tab {
	font-size: 4vw;
	line-height: 7vw;
	font-weight: bold;
	height: 7vw;
	padding: 0.5vw 3vw;
	font-size: 0.9rem;
	border-radius: 20vw;
	margin-right: 4vw;
	flex: none !important;
	background: linear-gradient(90deg, #5b606c, #2b2b2b);
	color: #fff;
}
::v-deep .van-tabs__nav {
	background: none;
}

::v-deep .van-tab--active {
	background: linear-gradient(#fde3ca, #e7b486);
	color: #8a5623;
}
::v-deep .van-tab__pane{
	margin-top: 3vw;
}
.van-tabs__line {
    display: none !important;
}
.putong{
	font-size: 3.667vw;
	color: #f3c7a2;
}
.nav-bar{
	background: #16151b !important;
	color: #f3c7a2;
	position: fixed;
	width: 100%;
	left: 0px;
	top: 0px;
	padding: 0px 3vw;
}
.swiper-item {
	display: block;
	height: 300rpx;
	line-height: 300rpx;
	text-align: center;
}
::v-deep .swiper-wrapper{
	width: 100%;
	height: 100%;
}
.ww100{
	width: 100%;
	height: 100%;
}
.banner{
	width: 100%;
	height: 42vw;
	margin-top: 3vw;
	// padding: 2vw 0vw 0vw 0vw;
}
.banner_swiper{
	width: 100%;
	height: 100%;
	border-radius: 3vw;
}
.bianhao{
	background: linear-gradient(90deg, #efcba7, #dcab76);
	color: #8d5825;
	display: inline-block;
	font-size: 0.8rem;
	font-weight: 600;
	padding: 8rpx 12rpx;
	border-radius: 0.833vw;
	padding-left: 0.8vw;
	margin-bottom: 10rpx;
	width: 32vw;
	
}
.uinfo{
	width: 70%;
}
.hang{
	font-size: 0.81rem;
	margin-bottom: 3px;
	line-height: 4vw;
}
.uinfo_cover{
	width: 30%;
    height: 25vw;
    overflow: hidden;
    border-radius: 10px;
}
::v-deep .uinfo_cover img{
}
.cover{
	display: inline-block;
    width: 25vw;
    height: 25vw;
    border-radius: 12.5vw;
    overflow: visible;
    background-color: transparent;
}
.item .hasicon {
	position: absolute;
	right: 0;
	top: 2.9vw;
	background: #ff6056;
	font-size: 0.8rem;
	padding: 0.7vw 1.5vw;
	font-style: normal;
	-webkit-transform: rotate(25deg);
	transform: rotate(25deg);
	color: #e7e7e7;
	z-index: 899;
}
.item .one{
	width: 100%;
	justify-content: space-between;
	align-items: center;
}
.ulist .item {
	background: linear-gradient(120deg, #212121, #313131);
	position: relative;
	overflow: hidden;
	margin-bottom: 10px;
	border-radius: 10px;
	border: 1px solid #7e6a56;
	padding: 2vw;
	display: flex;
	justify-content: space-between;
	color: #fff;
}
.huihui{
	font-size: 0.8rem;
    font-weight: normal;
    top: 0px;
    color: #8d8d8d;
    gap: 6px;
    align-items: center;
}
.convention-item{
	padding-bottom: 13vw;
}
.page{
	background: #212121 !important;
	padding: 0px 4vw;
	min-height: 100%;
}
#app{
	background: #212121;
}
uni-page, uni-page-body {
	background: #212121;
}
.page {
	position: absolute !important;
	top: 0;
	left: 0;
	right: 0;
	background-color: #f2f2f5;
}
.nav-bar {
	background: linear-gradient(90deg, #7e5678, #e6c3a1);
	height: 11vw;
}
.van-nav-bar {
	line-height: 50px;
}
::v-deep .van-nav-bar__title {
	max-width: 60%;
	margin: 0 auto;
	color: #f3c7a2;
	font-size: 35px;
}
::v-deep .van-nav-bar__content {
	height: 11vw;
}
.van-sidebar {
	width: 180px;
}
.convention-hall {
	display: flex;
	flex-direction: column;
}
::v-deep .van-tab {
	font-size: 30px;
	font-weight: bold;
}
::v-deep .van-tabs__line {
	background-color: #212121;
	display: none !important;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
	padding: 0 23px;
}
.card {
	background-color: #8a637d;
	padding: 0.625rem;
	color: white;
	font-size: 0.8125rem;
	margin: 0.625rem auto;
	border-radius: 0.375rem;
}
::v-deep .van-row--flex {
	height: 80px;
	line-height: 80px;
}
.rig-box {
	width: 95%;
	margin: 0.625rem auto;
}
.rig-title {
	color: #e1be9c;
	font-size: 1.125rem;
}
.rig-content {
	font-size: 0.8rem;
}
.address {
	width: 90%;
	margin: 0 auto;
}
</style>
