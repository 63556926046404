<template>
  <div class="movie-hall page">
    <van-nav-bar
        class="nav-bar"
        :title="$t('福利')"
    />
	<div class="movie-list-tab" style="padding: 10px 10px; background: url(img/lot/bg.png); background-size: cover;">
		<div class="orw" @click="gotoMenu('/Video')">
			<div class="hangline" style="gap: 20px;">
				<van-image class="cover_icon"  round src="img/lot/lt1.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<span style="color: white;">会员视频</span>
			</div>
			<div class="hangline">
				<span style="color: rgb(155 155 155);">疯狂癫狂颤抖高潮</span>
				<van-image class="cover_img" src="img/lot/man1.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<van-icon name="arrow" color="rgb(121 121 121)" size="18"/>
			</div>
		</div>
		
		<div class="orw" @click="gotoMenu('/Choose')">
			<div class="hangline" style="gap: 20px;">
				<van-image class="cover_icon"  round src="img/lot/lt2.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<span style="color: white;">新人福利</span>
			</div>
			<div class="hangline">
				<span style="color: rgb(155 155 155);">福利发不停</span>
				<van-image class="cover_img" src="img/lot/man2.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<van-icon name="arrow" color="rgb(121 121 121)" size="18" @click="back()"/>
			</div>
		</div>
		
		
		<div class="orw" @click="showFujin=true">
			<div class="hangline" style="gap: 20px;">
				<van-image class="cover_icon"  round src="img/lot/lt3.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<span style="color: white;">附近的人</span>
			</div>
			<div class="hangline">
				<span style="color: rgb(155 155 155);">为您发现同城炮友666人</span>
				<van-image class="cover_img" src="img/lot/man3.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<van-icon name="arrow" color="rgb(121 121 121)" size="18" @click="back()"/>
			</div>
		</div>
		
		<div class="orw" @click="gotoMenu('/Fangpian')" >
			<div class="hangline" style="gap: 20px;">
				<van-image class="cover_icon"  round src="img/lot/lt4.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<span style="color: white;">防骗指南</span>
			</div>
			<div class="hangline" style="display:none;">
				<span style="color: rgb(155 155 155);">为您发现同城炮友666人</span>
				<van-image class="cover_img" src="img/lot/man3.png">
					<template v-slot:loading>
						<van-loading type="circular"/>
					</template>
				</van-image>
				<van-icon name="arrow" color="rgb(121 121 121)" size="18" @click="back()"/>
			</div>
		</div>
		
		<div class="orw" style="margin-top: 30px;">
			<van-image class="hezuo" src="img/lot/hezuo.png">
				<template v-slot:loading>
					<van-loading type="circular"/>
				</template>
			</van-image>
		</div>
		
		
		<div class="orw" >
			<van-image class="imgs" src="img/lot/tongcheng.png" @click="gotoMenu('/Game')">
				<template v-slot:loading>
					<van-loading type="circular"/>
				</template>
			</van-image>
			<van-image class="imgs" src="img/lot/kongjian.png" @click="$router.push({path:'/Game?lotteryId=11&key=5'})">
				<template v-slot:loading>
					<van-loading type="circular"/>
				</template>
			</van-image>
		</div>
		
		<van-popup v-model="showFujin" position="center" style="{'height:100px;'}" >
			<div class="confirm-order-modal" style="background-color: #262626 !important;
			padding: 10px;
			height: 200px;
    border: .01rem solid #7e6a56;">
				<div class="head van-hairline--bottom" >
					<p class="text" style="color: #fe8595;">{{$t('为您发现同城炮友')}}</p>
					<p style="color: white;">本平台所有资源真实、安全、可靠、全国空降 选取您心仪的她 随时随地 开启约啪~</p>
					<a href="" style="background: linear-gradient(rgb(40, 88, 198), rgb(0, 0, 0));
    color: rgb(255, 255, 255);
    padding: 5px 30px;
    font-size: 1rem;
    border-radius: 0.04rem;
    border: none;
    margin: 0 auto;
    display: block;
    text-align: center;
    border-radius: 10px;
    width: 70%;">联系专属接待客服</a>
				</div>
			</div>
		</van-popup>
		
	</div>
  </div>
</template>

<script>
import { Toast } from 'vant';
export default {
  data() {
    return {
      active: 0,
      isLoading: false,
      count:0,
      loading: false,
      finished: false,
      refreshing: false,
      videolitem: [],
      videolist: [],
      number:0,
      page:0,
      videoSwiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 0,
        slidesPerGroup : 1,
      },
		showFujin:false,
    };
  },
  methods: {
	gotoMenu(router){
		this.$router.replace(router)
	},
    getVideoClass(){
      this.$http({
        method: 'get',
        url: 'video_class'
      }).then(res=>{
        this.videolitem = res.data;
      })
    },
    toPlayVideo(id){
      if(!localStorage.getItem('token')){
        this.$router.push({path:'/Login'})
      }else {
        this.$router.push({path:'/PlayVideo?id='+id})
      }

    },
    itemChange(){
      this.active = this.$refs.swiper.swiper.activeIndex
      this.OnChange()
    },
    getVideoList(){
      this.$http({
        method: 'get',
        data:{id:this.active,page:this.page},
        url: 'video_list'
      }).then(res=>{
        this.videolist = this.videolist.concat(res.data.data);
        this.count = res.data.count;
        this.page++;

      })
    },
    onLoad() {
        this.getVideoList();
      let timer = setTimeout(() => {
        if (this.refreshing) {
          this.videolist = [];
          this.refreshing = false;
        }
        this.loading = false;
        if (this.videolist.length === this.count) {
          this.finished = true;
        }
        this.finished && clearTimeout(timer);//清除计时器
      }, 500);
    },
     OnChange(){
      this.videolist = [];
      this.number = 0;
      this.page = 0;
      this.count = 0;
      this.getVideoList();//获取视频列表

    },
    onRefresh() {
      setTimeout(() => {
        this.finished = false;
        this.loading = true;
        this.onLoad();
        this.isLoading = false;
        Toast(this.$t('刷新成功'));
      }, 500);
    },
  },
  created() {
    this.getVideoClass();//获取视频类目
    this.OnChange()
  }
};
</script>

<style lang='less' scoped>
.hezuo{
	width: 240px;
	height: 60px;
}
.cover_icon{
	width: 50px;
	height: 50px;
}
.cover_img{
	width: 100px;
	height: 100px;
}
.imgs{
	width: 48%;
	height: 200px;
}
.orw{
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 30px;
}
.orw span{
	font-size: 0.8rem;
}
.hangline{
	gap: 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}
::v-deep .van-tabs__line {
    bottom: 3vw !important;
    width: 7.333vw;
    height: 0.833vw !important;
    border-radius: 0px;
    background-color: #ddb285 !important;
}
::v-deep .van-list{
	background: #212121;
}
::v-deep uni-page, uni-page-body {
	background: #212121;
}
::v-deep .van-nav-bar__content{
	background-color: rgb(40, 40, 40) !important;
	height: 11vw !important;
}
.page{
  position: absolute!important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}
.nav-bar{
  background: #212121;
  height: 11vw;

}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: rgb(221, 178, 133);
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}

.movie-hall{
  display: flex;
  flex-direction: column;
  bottom: 12vw;
  background: #f2f2f5;
}
::v-deep .van-tabs__nav {
  background: #212121;
  color: rgb(221, 178, 133);
}
::v-deep .van-tab {
  /* color: #ffffff; */
  color: rgb(221, 178, 133);
  font-size: 30px;
}

::v-deep .van-tabs--line .van-tabs__wrap {
  height: 100px;
}
::v-deep .van-tabs__wrap--scrollable .van-tab {
  padding: 0 23px;
}
::v-deep  .van-hairline--bottom::after {
  border-bottom-width: 0px;
}
.video_swiper {
  width: 100%;
  flex: 1;
  .swiper-slide {
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: 100%;
    justify-content: center;
    height: 100%;
    position: relative;
    transition-property: transform;
  }
}
.movie-list-tab {
  overflow: auto;
  height: 100%;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000;
  font-size: 35px;
}
.movie-list-tab .hot-recommend-div{
  height: 100%;
  margin: 0px auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item{
  display: flex;
  width: calc(100% - 50px);
  margin: 10px auto;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.list-item .movie-list-item:nth-child(odd) {
  margin-right: 20px;
}
.movie-list-item .cover_img{
  border-radius: 20px;
  width:335px;
  height:290px;
}
.movie-list-item{
  margin-bottom: -10px;
}
.list-item .movie-list-item-bottom{
  position: relative;
  width: 335px;
  bottom: 42px;
}
.list-item .movie-list-item-bottom .movie-time-div{
  background-color: rgba(0,0,0,.4);
}
.list-item .movie-list-item-bottom>div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.list-item .movie-list-item-bottom .movie-time-div .van-count-down {
  color: #fff;
}
.list-item .movie-list-item .movie-time-div span:first-child {
  overflow: hidden;
  white-space: nowrap;
  width: 180px;
  padding-left: 8px;
  font-size: 25px;
}
.list-item .movie-time-div {
  color: #fff;
  border-radius: 0 0 20px 20px;
  height: 35px;
}
</style>
