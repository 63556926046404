<template>
  <div class="container page">
    <van-nav-bar :title="$t('打赏记录')" class="nav-bar">
      <template #left>
        <van-icon name="arrow-left" color="#fff" @click="back()"/>
      </template>
    </van-nav-bar>
    <div class="main">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
		<div class="center" style="margin-top: 10px;">
			<van-image class="cover" style="width: 100px; height: 100px;" :src="'https://aqzwwz1.mlgm0pvq.shop'+detail.ico">
				<template v-slot:loading>
					<van-loading type="spinner"/>
				</template>
			</van-image>
		</div>
		<div class="center lottery_info">
			<span class="period-number" style="color: #fe8595; font-size: 1.2rem; font-weight: bold;">{{detail.name}}</span>
		</div>
		<div class="center lottery_info" style="padding: 10px; color:#ae7e27; border-bottom: 1px solid #fff;">
			第 <span class="period-number">{{detail.expect}}</span> 投积分据
		</div>
		
		<div class="center lottery_info" style="display: flex; justify-content: space-around; padding: 10px; color:#ae7e27; border-bottom: 5px solid #fff;">
			<div style="display: flex; flex-direction: column; justify-content: center; gap: 5px;">
				<span class="period-number" style="color: #fff;">{{1*detail.money}}</span>
				<span style="color: rgb(158 158 158);">任务积分</span>
			</div>
			<div style="display: flex; flex-direction: column; justify-content: center;">
				<span v-if="detail.data[0].status_text == '已结算'" style="color: #fff; font-size:1rem; font-weight: bold;">完成打赏</span>
				<span v-else style="color: #fff; font-size:1rem; font-weight: bold;">打赏进行中</span>
			</div>
		</div>
		
        <div class="item_list" style="display:flex; flex-direction: row; gap:10px;">
			<div style="color: white;">
				<div class="lottery_info">
					<span class="period-number" style="font-size: 1.1rem; color: white; font-weight: bold;">任务内容</span>
				</div>
				<div class="sha" style="margin-top: 10px;">
					<span>订单单号：</span>
					<span class="buy_item">{{detail.id}}</span>
				</div>
				<div class="sha" >
					<span>打赏内容：</span>
					<span class="buy_item" v-for="(v1,key1) in detail.data" :key="key1">{{formal[v1.type]}}</span>
				</div>
				<div class="sha" >
					<span>投积分量：</span>
					<span class="buy_item">{{1*detail.money}}</span>
				</div>
				<div class="sha" >
					<span>打赏时间：</span>
					<span class="buy_item">{{detail.create_time}}</span>
				</div>
			</div>
        </div>
		
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
		isLoading: false,
		list:[],
		detail: null,
		formal:{
			'大':'嘉年华',
			'小':'直升机',
			'单':'保时捷',
			'双':'火箭',
		},
		id:'',
    };
  },
  methods: {
    back(){
		this.$router.back(-1)
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast(this.$t('刷新成功'));
        this.isLoading = false;
      }, 500);
    },
    getUserGameDetail(){
      this.$http({
        method: 'get',
        url: 'user_get_game_detail',
		data:{
			id:this.id,
		},
      }).then(res=>{
        if(res.code === 200){
			console.log(res.data)
          this.detail = res.data[0];
        }else if(res.code ===401){
          this.$toast(res.msg);
        }
      })
    }

  },
  created() {
	this.id  = this.$route.query.id;

    if(!localStorage.getItem('token')){
      this.$router.push({path:'/Login'})
    }else {
      this.getUserGameDetail();
    }
	
  }
};
</script>

<style lang='less' scoped>
@import "../../assets/css/base.css";
.center{
	text-align: center;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head *{
  color: #000000;
  font-size: 35px;
}
.shule{
	color: red;
}
::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main{
	position: relative;
	overflow: auto;
	background-color: #2f2f2f;
	height: 100%;
	padding: 0 1.333vw;
}
.item_list{
  padding: 15px 15px;
  margin: 30px 10px;
  border-radius: 10px;
  line-height: 60px;
}
.sha{
	display: flex;
	gap: 10px;
	flex-wrap: wrap;
	align-items: center;
}
.buy_item{
	border: 1px solid #333;
	padding: 0px 10px;
	height: 1.5rem;
	line-height: 1.5rem;
}
.item_list .topInfo span{
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span{
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child{
  float: right;
}
.item_list .desc span{
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
.item_list .cover{
  width: 100px;
  height: 100px;
  -o-object-fit: cover;
  object-fit: cover;
}
.item_list .period-number{
  margin-right: 10px;
  height: 50px;
  line-height: 60px;
  font-size: 35px;
  font-weight: 700;
  color: #000;
}
.item_list .lottery_info{
  display: flex;
}
.recent {
  display: flex;
  align-items: center;
  height: 100px;
}
.kuaisan-ball .left{
  justify-content: flex-start;
}
.kuaisan-ball{
  flex: 1;
  display: flex;
  align-items: center;
}

.kuaisan-ball .res-img{
  width: 70px;
  height: 70px;
  margin-right: 30px;
}
.kuaisan-ball .res-des{
  font-weight: 700;
  text-align: center;
  color: #000;
}
.kuaisan-ball .res-des.middle{
  width: 15%;
  font-size: 35px;
}
</style>
